.text-default {
  display: inherit;
}

.text-default.color-white {
  color: #fff;
}

.text-default.pointer {
  cursor: pointer;
}

.text-default.underline {
  text-decoration: underline;
}

.text-default.nowrap {
  white-space: nowrap;
}
