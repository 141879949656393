@tailwind base;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600 underline;
  }
}

@tailwind components;
@tailwind utilities;

:where(.css-dev-only-do-not-override-fpg3f5).ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  @apply bg-main;
}

:root {
  --app-bg-color: #e50f0f;
  --header-bg-color: #e71718;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  font-size: 16px;
  line-height: 24px;
  min-height: 100vh;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-menu-item-selected {
  background-color: #e7eaee !important;
}

.ant-input-number,
.ant-input-number-input-wrap {
  width: 100%;
}

.ant-layout {
  overflow: auto;
}

.ant-layout-content {
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.app-container {
  height: 100%;
  width: 100%;
}

.f-width {
  width: 100%;
}
.f-height {
  height: 100%;
}
.d-flex {
  display: flex;
}

/* TEXT */
.text-left {
  text-align: left;
}

.ant-select-selector {
  @apply !h-full;
}

.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  td {
    min-width: 200px;
  }

  td:last-child {
    min-width: 150px;
  }
}
