.header-base {
  height: max-content;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline: 32px;
}

.header-base .wrap-header-logo .ant-image {
  height: 100%;
  display: flex;
  position: unset;
}

.header-base .wrap-header-logo .header-logo {
  cursor: pointer;
}

.header-base .wrap-header-logo .bell-icon {
  vertical-align: middle;
}

.header-base .row-custom {
  flex-wrap: nowrap;
}

.ant-badge-dot {
  transform: translate(-50%, -50%) !important;
}

.lang-box span {
  color: var(--header-bg-color);
}

.user-box span {
  color: var(--header-bg-color);
}
