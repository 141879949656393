.app-container {
  height: 100vh;
}

.app-container .ant-layout-content .page-container .page-header {
  width: 100%;
  justify-content: space-between;
}

.app-container .ant-layout-content .page-container .page-header .header-title {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  margin-bottom: 7px;
}

.app-container .ant-layout-content .page-container .page-form {
  height: inherit;
}

.app-container .ant-layout-content .page-container .page-form .form-content {
  height: calc(100% - 32px);
  overflow: auto;
}
