.layout-sider {
  max-height: "calc(100vh - 61px)";
  z-index: 4;
  border: 1px solid #eee;
  box-sizing: border-box;
  padding: 8px;
}

.layout-sider .btn-dropdown {
  padding: 12px 0;
  height: fit-content;
  overflow: hidden;
}

.layout-sider .btn-dropdown:hover {
  font-size: 14px;
}
.layout-sider .menu-sider {
  height: 100%;
  overflow-y: auto;
}
.layout-sider .menu-sider::-webkit-scrollbar {
  display: none;
}

.layout-sider.ant-layout-sider-collapsed
  .menu-sider.ant-menu.ant-menu-vertical
  .ant-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  justify-content: center;
  margin-inline: 0;
}

.layout-sider.ant-layout-sider-collapsed
  .menu-sider.ant-menu.ant-menu-vertical
  .ant-menu-item
  span.ant-menu-title-content {
  display: none;
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-layout
  .ant-layout-sider-children {
  position: fixed;
  width: 295px;
  top: 0;
  z-index: -1;
  padding-top: 50px;
}

.ant-layout-sider-children .menu-sider .ant-menu-item {
  margin: 0;
  margin-top: 8px;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.ant-layout-sider-children .menu-sider .ant-menu-item .ant-menu-title-content {
  text-transform: capitalize;
  font-weight: 600;
}
